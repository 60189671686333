<template>
  <div>
    <div class="card border-secondary">
        <div class="card-body">
                <div class="row mb-5">
                    <div class="col-sm-12">
                        <h3 >Solicitud ID  {{ `${solicitud.solicitud_id} | Fondeador: ${solicitud.fondeador.nombre}`   }} <br></h3>
                    </div>
                </div>
            <div class="row">
                <div class="col-sm-4  p-2">
                    <h5 class="custom-title-solicitud">Estatus:  {{estatus_solicitud}}</h5>
                    <p>
                        <b>Nombre:</b> {{ solicitud.solicitud.nombre }}  <br>
                        <b>Importe Solicitado:</b> {{ '$'+ $helper.moneyFormat(solicitud.solicitud.importe_solicitado) }} <br>
                        <b>Importe Preautorizado:</b> {{ '$'+ $helper.moneyFormat(solicitud.solicitud.importe_preautorizado)}} <br>
                        <b>Enviada a F y C:</b> {{ $moment(solicitud.created_at).format('DD MMMM YYYY HH:mm:ss') }}<br>
                        <b>Tipo de Fondeo:</b> {{ solicitud.nombre_tipo_fondeo }}<br>
                        <!-- <b>Estatus de la solicitud:</b> {{ estatus_solicitud }}<br> -->
                    </p>
                </div>
                <div class="col-sm-4  p-2">
                    <h5 class="custom-title-solicitud"> {{ solicitud.estatus_votacion == 1 ? 'Votación cerrada' : 'Votación abierta' }} </h5>
                    <p>
                        <b>Votos requeridos:</b> {{ solicitud.fondeador.votos_requeridos }} <br>
                        <b>Votos en contra:</b> {{ votos_en_contra }} <br>
                        <b>Votos a favor:</b> {{ votos_a_favor }} <br>
                        <!-- <b>Estatus de la votación:</b> {{ solicitud.estatus_votacion == 1 ? 'Votación cerrada' : 'Votación abierta' }} <br> -->
                    </p>
                </div>
                <div class="col-sm-4  p-2">
                    <h5 class="custom-title-solicitud">Comentarios</h5>
                    <template v-if="validar_comentarios===null">No hay comentarios</template>
                        <template v-else>
                            <p v-for="(voto, index) in solicitud.votos" :key="index">
                                <template v-if="voto.comentario">
                                    {{ voto.comentario }} <br> 
                                    <small><b>{{ voto.usuario_nombre }}</b></small>
                                </template>
                            </p>                               
                    </template>
                </div>
            </div>
            <div class="row">
                <template v-if="estatus_validos(solicitud.estatus, ['ESTATUS_SOLICITUD_SELECCIONADA_FORMALIZACION'])">
                    <div class="col-sm-2 offset-10">
                        <button class="btn  btn-block btn-danger" @click="modal_cancelar_solicitud=true">Cancelar solicitud</button>
                    </div>
                </template>       
                <template v-if="estatus_validos(solicitud.estatus, ['ESTATUS_SOLICITUD_ACEPTADA_FONDEADOR'])">
                    <div class="col-sm-3 offset-9">
                        <button class="btn  btn-block btn-primary" @click="modal_confirmar_fondeador=true">Seleccionar este fondeador</button>
                    </div>
                </template>
                <template v-if="estatus_validos(solicitud.estatus, ['ESTATUS_SOLICITUD_EN_ESPERA_DE_FORMALIZACION'])">
                    <div class="col-sm-2 offset-8">
                        <button class="btn  btn-block btn-danger" @click="modal_cancelar_solicitud=true">Cancelar Solicitud</button>
                    </div>
                    <div class="col-sm-2">
                        <button class="btn  btn-block btn-primary" @click="modal_reactivar_solicitud=true">Reactivar Solicitud</button>
                    </div>                    
                </template>                                          
            </div>
        </div>
    </div>

    <ConfirmarFondeador v-if="modal_confirmar_fondeador" @close="cerrar_modal_confirmar_fondeador" :solicitud="solicitud" />
    <ReactivarSolicitud v-if="modal_reactivar_solicitud" @close="cerrar_modal_reactivar_solicitud" :solicitud="solicitud" />
    <CancelarSolicitud v-if="modal_cancelar_solicitud" @close="cerrar_modal_cancelar_solicitud" :solicitud="solicitud" />

  </div>
</template>

<script>

import config from "@/apps/formalizacion/configuracion/solicitud";
import ConfirmarFondeador from "./ConfirmarFondeador";
import ReactivarSolicitud from "./ReactivarSolicitud";
import CancelarSolicitud from "./CancelarSolicitud";

export default {
    components:{
        ConfirmarFondeador, ReactivarSolicitud, CancelarSolicitud
    },
    props:{
        solicitud:null,
    },
    data(){
        return{
            modal_confirmar_fondeador:false,
            modal_reactivar_solicitud:false,
            modal_cancelar_solicitud:false,
        }
    },
    mounted(){
        this.$log.info('solicitud fyc', this.solicitud);
    },
    methods:{
        estatus_validos(valor, estatus){
            return config.solicitud.estatusValidos(valor, estatus);
        },
        cerrar_modal_confirmar_fondeador(){
            this.modal_confirmar_fondeador = false;
            this.$emit('update');
        },
        cerrar_modal_reactivar_solicitud(){
            this.modal_reactivar_solicitud = false;
            this.$emit('update');
        },
        cerrar_modal_cancelar_solicitud(){
            this.modal_cancelar_solicitud = false;
            this.$emit('update');
        }                           
    },    
    computed:{
        estatus_solicitud(){
            switch (this.solicitud.estatus) {
                case 0:
                    return 'Cancelada'
            
                case 1:
                    return 'Activa';
                
                case 2:
                    return 'Aceptada';
                
                case 3:
                    return 'Rechazada';
                
                case 4:
                    return 'En Espera Formalización';

                case 5:
                    return 'Seleccionada Formalización';                    

                case 6:
                    return 'Rechazada Formalización';
            }
        },
        votos_a_favor(){
            let votos_a_favor = 0;
            if(this.solicitud.votos && this.solicitud.votos.length > 0)
                this.solicitud.votos.forEach(voto => {
                    if(voto.tipo_voto == 1)
                        votos_a_favor += 1;
                });
            return votos_a_favor;
        },
        votos_en_contra(){
            let votos_en_contra = 0;
            if(this.solicitud.votos && this.solicitud.votos.length > 0)            
                this.solicitud.votos.forEach(voto => {
                    if(voto.tipo_voto == 0)
                        votos_en_contra += 1;
                });
            return votos_en_contra;
        },
        validar_comentarios(){
            let comentarios = null;
            if(this.solicitud.votos && this.solicitud.votos.length > 0)
                this.solicitud.votos.forEach(voto => {
                    if(voto.comentario!==null)
                        comentarios +=1;
                });
            return comentarios;
        },

    }

}
</script>

<style lang="scss" scoped>
    .custom-title-solicitud{
        background-color: #EDEDFA;
        padding: 5px;
    }


</style>