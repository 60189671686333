<template>
  <div>
    <Modal :options="{width: '40vw',type: 'danger'}" @close="$emit('close')">
      <div class="title">Cancelar</div>
      <div class="body">
        <p class="text-center">Estas por cancelar la solicitud ID: <strong>{{ solicitud.solicitud_id }}</strong> al fondeador:  
        <strong>{{ solicitud.fondeador.nombre }}</strong> ,<br/>¿Deseas continuar?, esta acción no puede deshacerse</p>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="cancelar">Confirmar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>

import Modal from "@/components/Modal";
import apicofinanciamiento from "@/apps/formalizacion/api/v2/cofinanciamiento";

export default {
    components:{
        Modal,
    },
    props:{
        solicitud:null,
    },
    methods:{
      async cancelar(){
        try {
          await apicofinanciamiento.cancelar_solicitud(this.solicitud._id); 
          this.$emit('close');             
        } catch (error) {
            this.$log.info('error', error);
            this.$helper.showAxiosError(error,'Error');           
        }
      }
    },
}
</script>

<style>

</style>